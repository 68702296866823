import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import Buttons from "../../components/Button/Button";
import Contrainer from "../../components/Contrainer/Contrainer";
import Header from "../../components/Header/Header";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import { useForm } from "antd/es/form/Form";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import { COMPAY_MAPPING_DES } from "../../definitions/Company";
import { PermissionReportDatasource } from "../../datasource/permissionReportDatasource";
import Swal from "sweetalert2";
import { AlignType } from "rc-table/lib/interface";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { EmployeeDatasource } from "../../datasource/employeeDatasource";

const CheckboxGroup = Checkbox.Group;

const PermissionReportSetting: React.FC = () => {
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);

  const [form] = useForm();
  const [data, setData] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const [empList, setEmpList] = useState<any>();
  const [searchEmp, setSearchEmp] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState(0);
  const [selfComp, setSelfComp] = useState<any>();
  const [pmsComp, setPmsComp] = useState<any>();
  const [kciComp, setKciComp] = useState<any>();
  const [error, setError] = useState<boolean>(false);

  const companyList = ["ICPF", "ICPI", "ICPL", "ICK"];

  const getPermissionList = async () => {
    await PermissionReportDatasource.getPermissionReport({
      page,
      search,
      take: 6,
    }).then((res) => {
      if (res.success) {
        setCount(res.responseData.count);
        setData(res.responseData.data);
      } else {
        setCount(1);
        setData([]);
      }
    });
  };

  const getAllEmp = async () => {
    await EmployeeApproveDatasource.getAllEmployee({
      page: 1,
      take: 6,
      search: searchEmp,
    }).then((res) => {
      setEmpList(res.responseData);
    });
  };

  const getPermissionById = async (id: string, empId: number) => {
    const dataEmp = await EmployeeDatasource.getEmployee(empId).then(
      (res) => res.responseData
    );
    setSearchEmp(dataEmp?.firstname || "");
    await PermissionReportDatasource.getPermissionById(id).then((res) => {
      const checkSelfMenu = res.responseData.permission.find(
        (x: any) => x.reportName === "Self-Reflection Report"
      );
      const checkPmsMenu = res.responseData.permission.find(
        (x: any) => x.reportName === "PMS Report"
      );
      const checkKciMenu = res.responseData.permission.find(
        (x: any) => x.reportName === "KCI Report"
      );
      setSelfComp(checkSelfMenu?.company || []);
      setPmsComp(checkPmsMenu?.company || []);
      setKciComp(checkKciMenu?.company || []);

      form.setFieldsValue({
        permissionReportId: res.responseData.permissionReportId,
        employeeId: res.responseData.employeeId,
        selfMenu: checkSelfMenu ? true : false,
        selfCompany: checkSelfMenu ? checkSelfMenu.company : [],
        pmsMenu: checkPmsMenu ? true : false,
        pmsCompany: checkPmsMenu ? checkPmsMenu.company : [],
        kciMenu: checkKciMenu ? true : false,
        kciCompany: checkKciMenu ? checkKciMenu.company : [],
      });
    });
  };

  useEffect(() => {
    getAllEmp();
    getPermissionList();
  }, [searchEmp, search, page]);

  const findEmpComId = (id: any) => {
    const find = empList.find((x: any) => `${x.employeeId}` === `${id}`);
    form.setFieldValue("compantId", find?.companyId);
  };
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Text>
              {row?.employee?.firstname} {row?.employee?.lastname}
            </Text>
          ),
        };
      },
    },
    {
      title: "Self-Reflection Summary",
      dataIndex: "Self-Reflection Report",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        const checkMenu = row.permission.filter(
          (x: any) => x.reportName === "Self-Reflection Report"
        );
        return {
          children: (
            <>
              {checkMenu.length ? (
                <>
                  <Row justify={"center"} key={index}>
                    <CheckCircleFilled
                      style={{ color: color.success, fontSize: "32px" }}
                    />
                  </Row>
                  <Row justify={"center"} key={index}>
                    {/* <Text>Company : </Text> */}
                    {checkMenu.map((x: any) => (
                      <Text>{x.company.join("/")}</Text>
                    ))}
                  </Row>
                </>
              ) : (
                <Row justify={"center"} key={index}>
                  <CloseCircleFilled
                    style={{ color: color.error, fontSize: "32px" }}
                  />
                </Row>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "PMS Summary",
      dataIndex: "PMS Report",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        const checkMenu = row.permission.filter(
          (x: any) => x.reportName === "PMS Report"
        );
        return {
          children: (
            <>
              {checkMenu.length ? (
                <>
                  <Row justify={"center"} key={index}>
                    <CheckCircleFilled
                      style={{ color: color.success, fontSize: "32px" }}
                    />
                  </Row>
                  <Row justify={"center"} key={index}>
                    {checkMenu.map((x: any) => (
                      <Text>{x.company.join("/")}</Text>
                    ))}
                  </Row>
                </>
              ) : (
                <Row justify={"center"} key={index}>
                  <CloseCircleFilled
                    style={{ color: color.error, fontSize: "32px" }}
                  />
                </Row>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "KCI Report",
      dataIndex: "KCI Report",
      align: "center" as AlignType,
      render: (value: any, row: any, index: number) => {
        const checkMenu = row.permission.filter(
          (x: any) => x.reportName === "KCI Report"
        );
        return {
          children: (
            <>
              {checkMenu.length ? (
                <>
                  <Row justify={"center"} key={index}>
                    <CheckCircleFilled
                      style={{ color: color.success, fontSize: "32px" }}
                    />
                  </Row>
                  <Row justify={"center"} key={index}>
                    {checkMenu.map((x: any) => (
                      <Text>{x.company.join("/")}</Text>
                    ))}
                  </Row>
                </>
              ) : (
                <Row justify={"center"} key={index}>
                  <CloseCircleFilled
                    style={{ color: color.error, fontSize: "32px" }}
                  />
                </Row>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"space-between"}>
              <Button
                style={{ color: color.navy }}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => {
                  setOpen(!open);
                  setIsEdit(true);
                  getPermissionById(row.permissionReportId, row.employeeId);
                }}
              />
              <Button
                style={{ color: color.error }}
                shape="circle"
                icon={<DeleteFilled />}
                onClick={() => {
                  deletePermission(row.permissionReportId);
                }}
              />
            </Row>
          ),
        };
      },
    },
  ];
  const onCheckAllChange = (e: any) => {
    const id = e.target.id;
    const checked = e.target.checked;
    if (id.includes("self")) {
      form.setFieldValue("selfCompany", checked ? companyList : undefined);
      setSelfComp(form.getFieldValue("selfCompany"));
    } else if (id.includes("pms")) {
      form.setFieldValue("pmsCompany", checked ? companyList : undefined);
      setPmsComp(form.getFieldValue("pmsCompany"));
    } else if (id.includes("kci")) {
      form.setFieldValue("kciCompany", checked ? companyList : undefined);
      setKciComp(form.getFieldValue("kciCompany"));
    } else {
      form.getFieldsValue();
    }
    setError(false);
  };
  const onCheckChange = (e: any, id: string) => {
    if (id.includes("self")) {
      form.setFieldValue("selfMenu", e.length > 0 ? true : false);
      form.setFieldValue("selfCompany", e);
      setSelfComp(form.getFieldValue("selfCompany"));
    } else if (id.includes("pms")) {
      form.setFieldValue("pmsMenu", e.length > 0 ? true : false);
      form.setFieldValue("pmsCompany", e);
      setPmsComp(form.getFieldValue("pmsCompany"));
    } else if (id.includes("kci")) {
      form.setFieldValue("kciMenu", e.length > 0 ? true : false);
      setKciComp(form.getFieldValue("kciCompany"));
    }
    setError(false);
  };
  const submit = async () => {
    const f = form.getFieldsValue(true);
    const payload: any = {};
    const permission: any = [];
    if (isEdit) {
      payload.permissionReportId = f.permissionReportId;
    } else {
      payload.createBy = profile.firstname + " " + profile.lastname;
    }
    payload.employeeId = f.employeeId;
    payload.updateBy = profile.firstname + " " + profile.lastname;
    if (f.selfMenu) {
      permission.push({
        reportName: "Self-Reflection Report",
        company: f.selfCompany,
      });
    }
    if (f.pmsMenu) {
      permission.push({
        reportName: "PMS Report",
        company: f.pmsCompany,
      });
    }
    if (f.kciMenu) {
      permission.push({
        reportName: "KCI Report",
        company: f.pmsCompany,
      });
    }
    payload.permission = permission;

    await PermissionReportDatasource.createPermission(payload).then((res) => {
      if (res.success) {
        setOpen(!open);
        form.resetFields();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
          width: 250,
        }).then(() => {
          setSearchEmp("");
          getPermissionList();
          setSelfComp([]);
          setPmsComp([]);
          setKciComp([]);
          setIsEdit(false);
        });
      }
    });
  };
  const deletePermission = async (id: string) => {
    await PermissionReportDatasource.deletePermissionById(id).then((res) => {
      if (res.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ลบข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 2000,
          width: 250,
        }).then(() => {
          setSearchEmp("");
          getPermissionList();
          form.resetFields();
          setSelfComp([]);
          setPmsComp([]);
          setIsEdit(false);
        });
      }
    });
  };
  const validateAtLeastOne = (_: unknown, value: any) => {
    const allValues = form.getFieldsValue();
    if (allValues?.selfMenu || allValues?.pmsMenu || allValues?.kciMenu) {
      return Promise.resolve();
    } else {
      setError(true);
      return Promise.reject(new Error("กรุณาเลือกรายงาน"));
    }
  };

  return (
    <>
      <Contrainer>
        <Header
          label="Permission Report Setting"
          color={color.navy}
          fontSize={30}
          headerHeight={"60Px"}
        />
        <br />
        <Row justify={"start"} gutter={16}>
          <Col span={21}>
            <Input
              style={{ fontFamily: "Sarabun", width: "30%" }}
              suffix={<SearchOutlined />}
              placeholder="ค้นหาชื่อพนักงาน"
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
          </Col>
          <Col>
            <Buttons
              onClick={() => setOpen(!open)}
              text="เพิ่มรายชื่อ"
              icon={<PlusCircleFilled style={{ color: "white" }} />}
            />
          </Col>
        </Row>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: color.navy,
                headerColor: color.white,
              },
            },
          }}
        >
          <Table dataSource={data || []} columns={columns} pagination={false} />
        </ConfigProvider>
        <br />
        <Row justify={"center"}>
          <Pagination
            current={page}
            total={count || 0}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
            pageSize={6}
          />
        </Row>
        <br />
      </Contrainer>
      {open && (
        <Modal
          title={
            <Text fontWeight={700} fontSize={24}>
              {isEdit ? "แก้ไข" : "เพิ่ม"} Permission
            </Text>
          }
          centered
          open={open}
          footer={false}
          onCancel={() => {
            setSearchEmp("");
            setSelfComp([]);
            setPmsComp([]);
            setKciComp([]);
            setOpen(!open);
            setIsEdit(!isEdit);
            form.resetFields();
            setError(false);
          }}
          width={700}
        >
          <Form layout="vertical" form={form} onFinish={submit}>
            <Col span={24}>
              <Form.Item
                label={<Text>ชื่อพนักงาน :</Text>}
                name="employeeId"
                rules={[
                  {
                    required: true,
                    message: "โปรดเลือกรายชื่ือ",
                  },
                ]}
              >
                <Select
                  disabled={isEdit}
                  allowClear
                  showSearch
                  onSearch={(e) => {
                    if (e) {
                      setSearchEmp(e);
                    } else {
                      setSearchEmp("");
                    }
                  }}
                  onChange={(e) => {
                    if (e) {
                      findEmpComId(e);
                      setSearchEmp(searchEmp);
                    } else {
                      setSearchEmp("");
                    }
                  }}
                  options={empList?.map((y: any) => {
                    return {
                      label: (
                        <Row>
                          <Text fontWeight={700}>
                            {y?.firstname +
                              " " +
                              y?.lastname +
                              " (" +
                              COMPAY_MAPPING_DES[`${y.companyId}`] +
                              ")"}
                          </Text>
                        </Row>
                      ),
                      value: y?.employeeId,
                      key: y?.firstname + " " + y?.lastname,
                    };
                  })}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) => {
                    return (option?.key ?? "").includes(input);
                  }}
                  filterSort={(optionA: any, optionB: any) => {
                    return (optionA?.key ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.key ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            </Col>
            <Row>
              <Text color="error" level={6}>
                *
              </Text>
              <Text level={5}>ชื่อ Report :</Text>
            </Row>
            <Row justify={"space-between"} gutter={16}>
              <Col span={12}>
                <ConfigProvider
                  theme={{
                    token: {
                      controlInteractiveSize: 22,
                    },
                  }}
                >
                  <Form.Item
                    name="selfMenu"
                    valuePropName="checked"
                    noStyle
                    dependencies={["employeeId"]}
                    rules={[{ validator: validateAtLeastOne }]}
                  >
                    <Checkbox onChange={onCheckAllChange}>
                      Self-Reflection Summary
                    </Checkbox>
                  </Form.Item>
                </ConfigProvider>
                <br />
                <Space direction="vertical" style={{ padding: "0 0 0 25px" }}>
                  <ConfigProvider
                    theme={{
                      token: {
                        controlInteractiveSize: 22,
                      },
                    }}
                  >
                    <Form.Item
                      name="selfCompany"
                      valuePropName="checked"
                      noStyle
                    >
                      <CheckboxGroup
                        options={companyList}
                        value={selfComp}
                        onChange={(e: any) => onCheckChange(e, "selfCompany")}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                        }}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Space>
              </Col>
              <Col span={12}>
                <ConfigProvider
                  theme={{
                    token: {
                      controlInteractiveSize: 22,
                      colorPrimary: color.success,
                    },
                  }}
                >
                  <Form.Item
                    name="pmsMenu"
                    valuePropName="checked"
                    noStyle
                    dependencies={["employeeId"]}
                    rules={[{ validator: validateAtLeastOne }]}
                  >
                    <Checkbox onChange={onCheckAllChange}>PMS Summary</Checkbox>
                  </Form.Item>
                </ConfigProvider>
                <br />
                <Space direction="vertical" style={{ padding: "0 0 0 25px" }}>
                  <ConfigProvider
                    theme={{
                      token: {
                        controlInteractiveSize: 22,
                        colorPrimary: color.success,
                      },
                    }}
                  >
                    <Form.Item
                      name="pmsCompany"
                      valuePropName="checked"
                      noStyle
                    >
                      <CheckboxGroup
                        options={companyList}
                        value={pmsComp}
                        onChange={(e: any) => onCheckChange(e, "pmsCompany")}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                        }}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Space>
              </Col>
            </Row>
            <br />
            <Row justify={"space-between"} gutter={16}>
              <Col span={12}>
                <ConfigProvider
                  theme={{
                    token: {
                      controlInteractiveSize: 22,
                      colorPrimary: color.darkyellow,
                    },
                  }}
                >
                  <Form.Item
                    name="kciMenu"
                    valuePropName="checked"
                    noStyle
                    dependencies={["employeeId"]}
                    rules={[{ validator: validateAtLeastOne }]}
                  >
                    <Checkbox onChange={onCheckAllChange}>KCI Report</Checkbox>
                  </Form.Item>
                </ConfigProvider>
                <br />
                <Space direction="vertical" style={{ padding: "0 0 0 25px" }}>
                  <ConfigProvider
                    theme={{
                      token: {
                        controlInteractiveSize: 22,
                        colorPrimary: color.darkyellow,
                      },
                    }}
                  >
                    <Form.Item
                      name="kciCompany"
                      valuePropName="checked"
                      noStyle
                    >
                      <CheckboxGroup
                        options={companyList}
                        value={kciComp}
                        onChange={(e: any) => onCheckChange(e, "kciCompany")}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                        }}
                      />
                    </Form.Item>
                  </ConfigProvider>
                </Space>
              </Col>
              <Col span={12}></Col>
            </Row>
            {error && (
              <>
                <br />
                <Row>
                  <Text level={6} color="error">
                    *กรุณาเลือก Permission
                  </Text>
                </Row>
              </>
            )}
          </Form>
          <br />
          <Row justify={"end"} gutter={8}>
            <Col>
              <Buttons
                bgColor={color.error}
                onClick={() => {
                  setSearchEmp("");
                  setSelfComp([]);
                  setPmsComp([]);
                  setKciComp([]);
                  setOpen(!open);
                  setIsEdit(!isEdit);
                  form.resetFields();
                  setError(false);
                }}
                text="ยกเลิก"
              />
            </Col>
            <Col>
              <Buttons
                bgColor={color.navy}
                text="บันทึก"
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default PermissionReportSetting;
