import React, { useEffect, useState } from "react";
import { Footer } from "antd/lib/layout/layout";
import {
  Card,
  Col,
  Row,
  Input,
  Badge,
  Checkbox,
  Space,
  Select,
  Button,
  message,
  Form,
  Spin,
  Modal,
  ConfigProvider,
} from "antd";
import Text from "../../components/Text/Text";
import color from "../../resource/color";
import Header from "../../components/Header/Header";
import Contrainer from "../../components/Contrainer/Contrainer";
import { SettingDatasource } from "../../datasource/settingDatasource";
import { PeriodList } from "../../definitions/Period";
import { SelfReflcetionDatasource } from "../../datasource/selfReflectionDatasoure";
import { useForm } from "antd/lib/form/Form";
import { YearList } from "../../definitions/Years";
import Swal from "sweetalert2";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const IndexSelfReflection: React.FC = () => {
  const [modal, contextModal] = Modal.useModal();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const [form] = useForm();
  const [formConditon] = useForm();
  const [loading, setLoading] = useState(false);
  const yearList = YearList();
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(
    new Date().getMonth() + 1 >= 10 ? 14 : 13
  );

  const [data, setData] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [condition, setCondition] = useState<any>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const getCondition = async () => {
    setData([]);
    form.resetFields();
    const selfReflce = await SelfReflcetionDatasource.getSelfReflection({
      employeeId: profile.employeeId,
      selfReflectionYear: year,
      selfReflectionMonth: month,
    }).then(async (res) => {
      form.setFieldsValue({
        isPrepareAppointment: !!res[0]?.isPrepareAppointment,
        isPrepareFeedback: !!res[0]?.isPrepareFeedback,
        isSubmit: !!res[0]?.isSubmit,
      });
      return res;
    });
    await SettingDatasource.getSelfCondition(year).then((res) => {
      setCondition(res);
      setLoading(true);
      const mapValue = res.map((x: any) => {
        const find = selfReflce.find(
          (a: any) => a.selfReflectionSettingId === x.selfReflectionSettingId
        );
        const selfId = find?.selfReflectionId || "";
        const add = x.sectionSubjectDetail.map((y: any, i: number) => {
          const value =
            find?.selfReflectionValue?.find((b: any) => b.key === y.key)
              ?.value || "";
          return {
            ...y,
            value: value || "",
            isValidate: false,
          };
        });
        return {
          ...x,
          selfReflectionId: selfId || "",
          sectionSubjectDetail: add,
        };
      });
      setData(mapValue);
      formConditon.setFieldValue("data", mapValue);
    });
  };

  useEffect(() => {
    setIsChecked(false);
    setLoading(false);
    getCondition();
  }, [year, month]);

  const onChangeTextArea = (e: any, section: number, key?: number) => {
    const form = formConditon.getFieldValue("data");
    const mapValue = form.map((x: any) => {
      const map = x.sectionOrder === section;
      if (map) {
        const sectionSubject = x.sectionSubjectDetail.map((y: any) => {
          const mapDetail = y.key === key;
          let v = { ...y };
          if (mapDetail) {
            return {
              ...v,
              value: e,
              isValidate: e ? false : y.requireFill ? true : false,
            };
          }
          return { ...v };
        });
        const newData = {
          ...x,
          sectionSubjectDetail: sectionSubject,
        };
        return newData;
      } else {
        return { ...x };
      }
    });
    setData(mapValue);
    formConditon.setFieldValue("data", mapValue);
  };
  const onSaveResult = async (submit?: boolean) => {
    const newCondition = formConditon.getFieldValue("data");

    let newData = newCondition.map((x: any) => {
      const data = {
        selfReflectionId: x.selfReflectionId || "",
        employeeId: Number(profile.employeeId),
        selfReflectionYear: year,
        selfReflectionSettingId: x.selfReflectionSettingId,
        isSubmit: submit || false,
        createBy: profile.firstname + " " + profile.lastname,
        updateBy: profile.firstname + " " + profile.lastname,
        isPrepareAppointment: form.getFieldValue("isPrepareAppointment"),
        isPrepareFeedback: form.getFieldValue("isPrepareFeedback"),
        selfReflectionMonth: month,
        selfRefelcValue: x.sectionSubjectDetail,
      };
      return data;
    });
    console.log("newData", newData);
    await SelfReflcetionDatasource.createSelfReflcetion(newData).then((res) => {
      if (res.success) {
        messageApi.open({
          type: "success",
          content: "Auto Save",
        });
        const mapValue = condition.map((x: any) => {
          const find = res.responseData.find(
            (a: any) => a.selfReflectionSettingId === x.selfReflectionSettingId
          );
          const selfId = find?.selfReflectionId || "";
          const add = x.sectionSubjectDetail.map((y: any, i: number) => {
            const value =
              find?.selfReflectionValue?.find((b: any) => b.key === y.key)
                ?.value || "";
            return {
              ...y,
              value: value || "",
              isValidate: false,
            };
          });
          return {
            ...x,
            selfReflectionId: selfId || "",
            sectionSubjectDetail: add,
          };
        });
        formConditon.setFieldValue("data", mapValue);
      }
    });
    submit && getCondition();
  };
  const submit = async () => {
    const confirmed = await modal.confirm({
      title: <Text fontWeight={700}>ยืนยันการ Submit Self Reflection</Text>,
      icon: <ExclamationCircleOutlined style={{ fontSize: "25px" }} />,
      content: "",
      okText: (
        <Text color="white" fontWeight={700}>
          ยืนยัน
        </Text>
      ),
      cancelText: <Text fontWeight={700}>ยกเลิก</Text>,
      centered: true,
    });
    if (confirmed) {
      let validate = false;
      const map = data;
      const checkValidate = map.map((x: any) => {
        const sectionSubject = x.sectionSubjectDetail.map((y: any) => {
          const mapDetail = y.requireFill && y.value === "";
          let v = { ...y };
          if (mapDetail) {
            validate = true;
            return { ...v, isValidate: true };
          }
          return { ...v, isValidate: false };
        });
        return { ...x, sectionSubjectDetail: sectionSubject };
      });
      const isCheck = form.getFieldsValue(true);
      setIsChecked(!isCheck.isPrepareFeedback || !isCheck.isPrepareAppointment);
      setData(checkValidate);
      if (
        validate ||
        !isCheck.isPrepareFeedback ||
        !isCheck.isPrepareAppointment
      ) {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบ",
          showConfirmButton: true,
          width: 360,
        });
      } else {
        await onSaveResult(true);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Sumbit สำเร็จ",
          showConfirmButton: false,
          timer: 2000,
          width: 250,
        });
      }
    }
  };
  const Title = () => {
    return (
      <>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Employee Name :
                </Text>
                <br />
                <Text color="navy">ชื่อพนักงาน</Text>
              </Col>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Text fontWeight={700} fontSize={20} color="green">
                    {profile.firstname} {profile.lastname}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Supervisor Name :
                </Text>
                <br />
                <Text color="navy">ชื่อผู้บังคับบัญชา</Text>
              </Col>
              <Col span={12}>
                <Row justify={"space-around"}>
                  {profile?.employeeApprove?.employeeApproveDetail ? (
                    <Text fontWeight={700} fontSize={20} color="green">
                      {profile?.employeeApprove?.employeeApproveDetail
                        ?.firstname +
                        " " +
                        profile?.employeeApprove?.employeeApproveDetail
                          ?.lastname}
                    </Text>
                  ) : (
                    <Text fontWeight={700} fontSize={20} color="error">
                      ไม่มีผู้อนุมัติ กรุณาตั้งค่า
                    </Text>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Position :
                </Text>
                <br />
                <Text color="navy">ตำแหน่ง</Text>
              </Col>
              <Col span={12}>
                <Row justify={"space-around"}>
                  <Text fontWeight={700} fontSize={20} color="green">
                    {profile?.position?.positionName}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={6}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Year :
                </Text>
                <br />
                <Text color="navy">ปี ค.ศ.</Text>
              </Col>
              <Col span={6}>
                <Select
                  bordered={false}
                  onChange={(e) => setYear(e)}
                  style={{ width: "80%", height: "80%" }}
                  defaultValue={year}
                  options={yearList.map((y: any) => {
                    return {
                      label: (
                        <Text color="darkyellow" fontWeight={700}>
                          {y}
                        </Text>
                      ),
                      value: y,
                    };
                  })}
                />
              </Col>
              <Col span={6}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Types :
                </Text>
                <br />
                <Text color="navy">ประเภท</Text>
              </Col>
              <Col span={6}>
                <Select
                  bordered={false}
                  style={{
                    width: "80%",
                    height: "80%",
                  }}
                  onChange={(e) => setMonth(e)}
                  defaultValue={month}
                  options={PeriodList.map((y) => {
                    return {
                      label: (
                        <Text color="darkyellow" fontWeight={700}>
                          {y.month}
                        </Text>
                      ),
                      value: y.key,
                    };
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ padding: "10px" }}>
          <Col span={12}>
            <Row justify={"space-between"}>
              <Col span={12}>
                <Text fontWeight={700} fontSize={20} color="navy">
                  Job Description (JD) :
                </Text>
                <br />
                <Text color="navy">หน้าที่</Text>
              </Col>
              {profile.position.team.department.company.companyId === `1` && (
                <Col span={12}>
                  <Row justify={"space-around"}>
                    <Text
                      fontWeight={700}
                      fontSize={20}
                      color="darkyellow"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        href="https://icpladda.monday.com/boards/521144129/views/145281099"
                        target="_blank"
                        style={{
                          color: color.darkyellow,
                          textDecoration: "underline",
                        }}
                      >
                        คลิกเพื่อดู JD
                      </a>
                    </Text>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={12}></Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {contextModal}
      {contextHolder}
      <Contrainer>
        <Header
          label="Self-Reflection Form"
          fontSize={30}
          color={color.navy}
          headerHeight={"60Px"}
        />
        {Title()}
        {!loading ? (
          <Row justify={"center"}>
            <Spin style={{ paddingTop: "10%" }} />
          </Row>
        ) : (
          <>
            {data.length > 0 &&
              data?.map((header: any) => (
                <>
                  <Header
                    label={header.sectionName}
                    fontSize={20}
                    color={color.green}
                  />
                  <br />
                  {header.sectionSubjectDetail.map((detail: any) => (
                    <Row
                      justify={"space-between"}
                      gutter={16}
                      style={{ padding: "8px" }}
                    >
                      <Col span={12}>
                        <Badge.Ribbon
                          text={
                            <Text fontWeight={700} color="white" fontSize={24}>
                              {detail.key}
                            </Text>
                          }
                          placement="start"
                          color={color.navy}
                        >
                          <Card
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#E1E5F2",
                              padding: "30px",
                            }}
                          >
                            <Text fontWeight={700}>
                              {detail.requireFill && (
                                <Text color="error" fontWeight={700}>
                                  *
                                </Text>
                              )}{" "}
                              {detail.sectionSubjectTitle}{" "}
                              {detail.sectionSubjectDetail &&
                                `: ${detail.sectionSubjectDetail}`}
                            </Text>
                          </Card>
                        </Badge.Ribbon>
                      </Col>
                      <Col span={12}>
                        <TextArea
                          onBlur={async () => await onSaveResult()}
                          rows={8}
                          style={{
                            width: "100%",
                            borderColor: detail.isValidate && color.error,
                            color: color.black,
                            fontFamily: "Sarabun",
                            fontWeight: "bold",
                          }}
                          placeholder="กรุณาพิมพ์คำตอบที่นี่..."
                          defaultValue={detail.value}
                          onChange={(e) =>
                            onChangeTextArea(
                              e.target.value,
                              header.sectionOrder,
                              detail.key
                            )
                          }
                          disabled={form.getFieldValue("isSubmit")}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              ))}
            {data.length > 0 ? (
              <Row justify={"center"} style={{ padding: "20px" }}>
                <Form form={form} layout="vertical">
                  <Space direction="vertical">
                    <ConfigProvider
                      theme={{
                        components: {
                          Checkbox: {
                            colorBgContainerDisabled: form.getFieldValue(
                              "isPrepareAppointment"
                            )
                              ? color.navy
                              : "",
                          },
                        },
                      }}
                    >
                      <Form.Item
                        name="isPrepareAppointment"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          disabled={form.getFieldValue("isSubmit")}
                          onChange={(e) => setIsChecked(!e)}
                        >
                          <Text fontWeight={700}>
                            ได้เตรียม ลงตารางนัดหมายกับ หัวหน้าเรียบร้อย
                          </Text>
                        </Checkbox>
                      </Form.Item>
                    </ConfigProvider>
                    <ConfigProvider
                      theme={{
                        components: {
                          Checkbox: {
                            colorBgContainerDisabled: form.getFieldValue(
                              "isPrepareFeedback"
                            )
                              ? color.navy
                              : "",
                          },
                        },
                      }}
                    >
                      <Form.Item
                        name="isPrepareFeedback"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          disabled={form.getFieldValue("isSubmit")}
                          onChange={(e) => setIsChecked(!e)}
                        >
                          <Text fontWeight={700}>
                            ได้เตรียม Feedback สำหรับการคุยกับหัวหน้าเรียบร้อย
                          </Text>
                        </Checkbox>
                      </Form.Item>
                    </ConfigProvider>
                    {isChecked && (
                      <Text fontWeight={600} color="error">
                        *กรุณาเลือก
                      </Text>
                    )}
                  </Space>
                </Form>
              </Row>
            ) : (
              <Row justify={"center"} style={{ paddingTop: "10%" }}>
                <Text fontSize={40} color="gray">
                  ยังไม่ถึงกำหนดการประเมิน
                </Text>
              </Row>
            )}
          </>
        )}
      </Contrainer>
      <Footer style={{ backgroundColor: color.white }}>
        {data.length > 0 && !form.getFieldValue("isSubmit") && (
          <Row justify={"center"}>
            <Button
              style={{ backgroundColor: color.navy, height: "40px" }}
              onClick={() => submit()}
            >
              <Text color="white" fontWeight={700}>
                SUBMIT
              </Text>
            </Button>
          </Row>
        )}
      </Footer>
    </>
  );
};
export default IndexSelfReflection;
