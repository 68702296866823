import { Col, Row, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import Contrainer from "../../components/Contrainer/Contrainer";
import Text from "../../components/Text/Text";
import { kciYearList } from "../../definitions/Years";

const KciReport: React.FC = () => {
  const yearList = kciYearList();
  const companyList = ["ICPF", "ICPI", "ICPL", "ICK"];
  const [selectYear, setSelectYear] = useState<number>(
    new Date().getFullYear()
  );
  return (
    <div>
      <Content
        style={{
          backgroundColor: "#F4F6F4",
          paddingLeft: "15px",
          paddingRight: "px",
        }}
      >
        <br />
        <Contrainer
          style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
        >
          <Row justify={"space-between"}>
            <Col span={18}>
              <Text fontSize={20} fontWeight={700}>
                KCI Status Report {selectYear}
              </Text>
            </Col>
            <Col span={3}>
              <Select
                showSearch
                bordered={true}
                value={selectYear}
                options={yearList.map((x: any) => {
                  return {
                    label: (
                      <Text color="black" fontWeight={700} fontSize={16}>
                        {x}
                      </Text>
                    ),
                    value: x,
                    key: x,
                  };
                })}
                onChange={(key) => setSelectYear(key)}
                virtual={false}
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "scroll",
                }}
              />
            </Col>
            <Col span={3}>
              <Select
                showSearch
                bordered={true}
                value={"ICK"}
                options={companyList.map((x: any) => {
                  return {
                    label: (
                      <Text color="black" fontWeight={700} fontSize={16}>
                        {x}
                      </Text>
                    ),
                    value: x,
                    key: x,
                  };
                })}
                //onChange={(key) => setSelectYear(key)}
                virtual={false}
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "scroll",
                }}
              />
            </Col>
          </Row>
        </Contrainer>
        <br />
      </Content>
    </div>
  );
};
export default KciReport;
